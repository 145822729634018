export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

export type Prettify<T> = {
  [K in keyof T]: T[K]
  // eslint-disable-next-line @typescript-eslint/ban-types
} & {}

export enum Cookies {
  Essential = 'ESSENTIAL',
  Analytics = 'ANALYTICS',
}

export interface CarrierStatus {
  carrierVettingStatus: CarrierVettingStatus
  accountSource: string
  isDenylisted: boolean
}

export enum CarrierVettingStatus {
  PENDING = 'PENDING',
  ASSIGNED = 'ASSIGNED',
  VETTED = 'VETTED',
  FAILED = 'FAILED',
}
