import { Tenant } from '@sennder/senn-node-microfrontend-interfaces'

/**
 * Parses tenant from the URL. Defaults to SENNDER if no tenant is found.
 */
export const parseTenantFromUrl = (): Tenant => {
  if (location.hostname.endsWith('.it')) {
    return Tenant.POSTE
  }
  if (location.hostname.includes(Tenant.DEMO)) {
    return Tenant.DEMO
  }
  return Tenant.SENNDER
}
