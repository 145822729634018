import { HttpClient } from './http-client/HttpClient'
import httpProvider from './http-provider'
import { getAuth0Header } from '@/services/tokenManager'
import { IUserCompany } from '@sennder/senn-node-microfrontend-interfaces'
import { getMothershipUrl } from '@/common/config'
import { parseTenantFromUrl } from './tenant'

class MothershipService extends HttpClient {
  public async getCompanyDetails(): Promise<IUserCompany> {
    return this.get('/ma-franchise/queries/company-details/')
  }
}

const mothershipUrl = getMothershipUrl(parseTenantFromUrl())
if (!mothershipUrl) {
  throw new Error('mothershipUrl not found')
}

export default new MothershipService({
  httpProvider,
  getAuthHeader: getAuth0Header,
  baseURL: mothershipUrl,
})
