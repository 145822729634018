import { store, getEmptyData, OrcasStoreData } from '@/store'

export const setStoreLanguage = async (language: string) => {
  store.state.data.language = language
}

export const clearState = () => {
  store.state.data = getEmptyData()
}

export const setStoreFeatureFlags = (
  featureFlags: Record<string, boolean | string>
) => {
  store.state.data.featureFlags = featureFlags
}

export const setStoreData = (data: OrcasStoreData) => {
  store.state.data = data
}
