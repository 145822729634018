import { IHTTPProvider, RequestOptions } from './types'
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js'

type GetAuthHeaderFn = (
  options?: GetTokenSilentlyOptions
) => Promise<null | string>

interface Options {
  httpProvider: IHTTPProvider
  getAuthHeader: GetAuthHeaderFn
  baseURL: string
}

export class HttpClient {
  private httpProvider: IHTTPProvider
  private readonly getAuthHeader: GetAuthHeaderFn
  private readonly baseUrl: string

  constructor(options: Options) {
    if (!options.baseURL) {
      throw Error('[orcas-shell - HttpClient]: Base url is empty')
    }
    this.httpProvider = options.httpProvider
    this.getAuthHeader = options.getAuthHeader
    this.baseUrl = options.baseURL
  }

  private async buildRequest(
    options: Omit<RequestOptions, 'baseUrl'>
  ): Promise<RequestOptions> {
    if (!options) {
      throw Error('[orcas-shell - HttpClient]: Request options are empty')
    }

    const { headers, ...rest } = options
    const authHeader = await this.getAuthHeader()

    if (!authHeader) {
      throw Error(
        '[orcas-shell - HttpClient]: Cant build a request in HttpClient. Auth header is empty'
      )
    }

    return {
      baseUrl: this.baseUrl,
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader,
        ...headers,
      },
      ...rest,
    }
  }

  public async get<Res = object>(
    path: string,
    options: Omit<RequestOptions, 'baseUrl'> = {}
  ) {
    return this.httpProvider.get<Res>(path, await this.buildRequest(options))
  }

  public async post<Res = object>(
    path: string,
    options: Omit<RequestOptions, 'baseUrl'> = {}
  ) {
    return this.httpProvider.post<Res>(path, await this.buildRequest(options))
  }

  public async put<Res = object>(
    path: string,
    options: Omit<RequestOptions, 'baseUrl'> = {}
  ) {
    return this.httpProvider.put<Res>(path, await this.buildRequest(options))
  }

  public async delete<Res = object>(
    path: string,
    options: Omit<RequestOptions, 'baseUrl'> = {}
  ) {
    return this.httpProvider.delete<Res>(path, await this.buildRequest(options))
  }
}
