import { logger } from '@/services/logger/loggers'
import { CARRIER_USER_SERVICE_URL } from '@/common/config'

const CACHE_DURATION = 60 // cache the ip address for 60 seconds
const CACHE_KEY = 'orcas-user-ip-cache'

export const userIpManager = {
  async get() {
    const cachedEntry = window.localStorage.getItem(CACHE_KEY)
    if (cachedEntry) {
      const cachedEntryObj = JSON.parse(cachedEntry)
      if (cachedEntryObj['expiry'] > new Date().getTime()) {
        return cachedEntryObj['ipAddress']
      }
    }

    const resolvedIpAddress = await getUserIpAddress()
    if (resolvedIpAddress) {
      userIpManager.cache(resolvedIpAddress)
    }
    return resolvedIpAddress
  },
  cache(ipAddress: string) {
    const currentDate = new Date().getTime()
    const expiry = new Date(currentDate + CACHE_DURATION * 1000).getTime()
    window.localStorage.setItem(
      CACHE_KEY,
      JSON.stringify({ expiry, ipAddress })
    )
  },
}

export const getUserIpAddress = async (): Promise<string | undefined> => {
  const controller = new AbortController()
  const timeout = setTimeout(() => controller.abort(), 1000)
  try {
    const response = await fetch(`${CARRIER_USER_SERVICE_URL}/api/info`, {
      method: 'GET',
      signal: controller.signal,
    })
    if (response.ok) {
      const data = await response.json()
      return data.user_ip
    } else {
      logger.warn('Could not resolve user Ip address', { error: response.body })
    }
  } catch (error) {
    logger.error('Error occurred while fetching user ip address', { error })
  } finally {
    clearTimeout(timeout)
  }
  return
}
