import { watch } from 'vue'

import { FederatedModule } from '@sennder/federated-module-loader'
import {
  INotificationCenterData,
  INotificationCenterMicrofrontendData,
  MountNotifications,
  SharedDataType,
} from '@sennder/senn-node-microfrontend-interfaces'

import { AUTH0_API_AUDIENCE, ENV } from '@/common/config'
import errorsHandler from '@/services/errors-handler'
import { getStateCallbacks, getStateData } from '@/store/getters'
import { logger } from './logger/loggers'
import { analytics, AppAnalyticsProvider } from './analyticsProvider'
import router from '@/router'
import { getAuth0Token, parseTenantFromToken } from './tokenManager'
import {
  cloneReactiveToRaw,
  MicroFrontendLogger,
} from '@sennder/shell-utilities'
import { loggerInstance } from './logger'

export const notificationsModule = new FederatedModule<INotificationCenterData>(
  {
    devPort: 9199,
    environment: ENV || 'prod',
    moduleFederationPluginName: 'notificationcentermf',
    npmName: 'notification-center-mf',
    cacheType: 'infinite',
    logger,
  }
)

let mountFunction: MountNotifications
let unmount: (() => void) | null = null
let unwatch: (() => void) | null = null

async function getNotificationCenterData(): Promise<INotificationCenterData> {
  return {
    shell: SharedDataType.ORCAS,
    language: getStateData().language,
    featureFlags: getStateData().featureFlags,
    tenant: await parseTenantFromToken(),
  }
}

async function loadNotificationsCenter(elementId: string) {
  if (!mountFunction) {
    mountFunction = (
      await notificationsModule.getModule<{ mount: MountNotifications }>(
        './notificationcentermfApp'
      )
    )?.mount
  }
  const microfrontendData: INotificationCenterMicrofrontendData = {
    data: await getNotificationCenterData(),
    callbacks: {
      getAuthHeader: getStateCallbacks().getAuthHeader,
      getAuthToken: (options) =>
        getAuth0Token({
          ...options,
          audience: AUTH0_API_AUDIENCE,
        }),
      getCommonHeaders: getStateCallbacks().getCommonHeaders,
      navigate: async (route) => {
        analytics.forceTrackPageEvent()
        await router.push(route)
      },
    },
    providers: {
      logger: new MicroFrontendLogger(
        {
          module: 'notifications',
          codeOwners: 'frontend-systems',
        },
        () => loggerInstance
      ),
      analytics: new AppAnalyticsProvider({
        module: 'notifications',
        submodule: '',
      }),
    },
  }
  const mountResult = mountFunction(`#${elementId}`, microfrontendData)

  unwatch = watch(
    () => getStateData(),
    async () => {
      mountResult.onSharedDataChanged(
        cloneReactiveToRaw(await getNotificationCenterData())
      )
    },
    { deep: true }
  )

  unmount = mountResult.unmount
}

export async function initNotifications(elementId: string) {
  try {
    await loadNotificationsCenter(elementId)
  } catch (error: any) {
    errorsHandler(error)
  }
}

export function stopNotifications() {
  unwatch?.()
  unmount?.()
}
