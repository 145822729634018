import { ENV } from '@/common/config'
import { ModuleConfig } from '@/modules-configuration'
import { FederatedModule } from '@sennder/federated-module-loader'
import { IOrcasSharedData } from '@sennder/senn-node-microfrontend-interfaces'
import { logger } from '@/services/logger/loggers'

export const getFederatedConfig = (component: ModuleConfig) => {
  const NAMESPACE = component.component.replace(/[^a-zA-Z]/g, '')
  const REMOTE = `./${NAMESPACE}App`

  return new FederatedModule<IOrcasSharedData>({
    devPort: parseInt(component.devPort.toString()),
    environment: ENV,
    moduleFederationPluginName: NAMESPACE,
    npmName: component.component,
    mountExposedName: REMOTE,
    cacheType: 'infinite',
    logger,
  })
}
