import { AppBannerOptions } from '@sennder/senn-node-microfrontend-event-bus'
import { i18n } from '@/services/i18n'

export function getAppBannerOptions(): AppBannerOptions {
  return {
    author: i18n.global.t('app.promotional.banner.header'),
    button: i18n.global.t('app.promotional.banner.view'),
    buttonUrlApple: 'https://apps.apple.com/de/app/sennder-driver/id1328308931',
    buttonUrlGoogle:
      'https://play.google.com/store/apps/details?id=com.sennder.android.driver',
    iconApple:
      'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/07/c8/5b/07c85b78-3880-7a76-ae6b-855790f83a67/AppIcon-1x_U007emarketing-0-5-0-85-220.png/460x0w.webp',
    iconGoogle:
      'https://play-lh.googleusercontent.com/tIotsU5xvM8Umc2exPVp9AABk9V3dFw7LweFilB9vprhOnsht_Rjq7Zjs6IgVn7hNsk=w480-h960-rw',
    title: 'sennder',
    price: '',
  }
}
