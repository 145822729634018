import { isNotAuthenticated } from '@/router/middlewares/middlewares'

// Todo: add feature flag for signup
export default [
  {
    path: '/signup',
    name: 'signup',
    meta: { middlewares: [isNotAuthenticated], analyticsName: 'signup' },
    component: () => import('./SignupMicrofrontend.vue'),
  },
  {
    path: '/signup-successful',
    name: 'signupSuccessful',
    meta: { middlewares: [isNotAuthenticated], analyticsName: 'signup' },
    component: () => import('./SignupMicrofrontend.vue'),
  },
]
