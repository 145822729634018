import { createI18n } from 'vue-i18n'
import { App } from 'vue'

export const languages = [
  { label: 'English', value: 'en' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Español', value: 'es' },
  { label: 'Français', value: 'fr' },
  { label: 'Italiano', value: 'it' },
  { label: 'Polski', value: 'pl' },
  { label: 'Pусский', value: 'ru' },
  { label: 'Português', value: 'pt' },
]

export const defaultLanguage = 'en'

export const i18n = createI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  messages: {},
})

export function registerI18nPlugin(app: App) {
  app.use(i18n)
}
