import {
  MicrofrontendEvents,
  onDispatchToastNotification,
  publish,
} from '@sennder/senn-node-microfrontend-event-bus'

import { i18n } from '@/services/i18n'

export const notify = (
  message: string,
  category: onDispatchToastNotification['category'],
  title?: string
) => {
  publish<onDispatchToastNotification>({
    name: MicrofrontendEvents.onDispatchToastNotification,
    data: {
      message,
      category,
      title:
        title ||
        (category === 'error' ? i18n.global.t('toast.error.title') : undefined),
    },
  })
}
