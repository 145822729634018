import { App } from 'vue'
import { initGTMPlugin } from '@/plugins/gtManager'
import { registerI18nPlugin } from '@/services/i18n'
import { registerSmartBanner } from '@/plugins/smart-banner'

let _appInstance: App | null = null

export function initGTM() {
  if (_appInstance) {
    initGTMPlugin(_appInstance)
  }
}

export function registerPlugins(app: App) {
  _appInstance = app
  registerI18nPlugin(app)
  registerSmartBanner()
}
