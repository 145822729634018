import '@/assets/tailwind.global.css'
import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import { initializeVue as initLaunchDarkly } from '@sennder/senn-node-feature-flags-frontend'
import {
  getAuth0ClientId,
  AUTH0_DOMAIN,
  AUTH0_MOTHERSHIP_AUDIENCE,
  LD_KEY,
  isLocalEnv,
} from '@/common/config'
import { initAuth0 } from '@sennder/senn-node-auth0-frontend'
import { registerPlugins } from '@/plugins'
import { translationProvider } from '@/services/translationProvider'
import { loadUserData } from '@/store'
import { logger } from '@/services/logger/loggers'
import '@sennder/design-system-core'
import { registerModalManager } from '@/components/modal-manager'
import { registerRoutesAndMiddlewares } from '@/router/routes'
import { getCookieConsent } from '@/services/cookies'
import { startTracking } from '@/services/tracking'
import { getLaunchDarklyAnonymousContext } from './services/launchDarkly'
import { parseTenantFromUrl } from './services/tenant'
import '@sennder/shell-utilities/dist/style.css'
import { envOverrides } from '@sennder/shell-utilities'
import { loadStyles, themeProvider, Themes } from '@sennder/design-system-core'

async function initialize() {
  window.onerror = function (message, url, line, column, error) {
    logger.error('[Orcas shell - global error]', { error })
  }

  const auth0ClientId = getAuth0ClientId(parseTenantFromUrl())
  if (!auth0ClientId) {
    throw new Error('auth0ClientId not found')
  }
  if (!AUTH0_DOMAIN) {
    throw new Error('AUTH0_DOMAIN not found')
  }

  await initAuth0({
    client_id: auth0ClientId,
    domain: AUTH0_DOMAIN,
    audience: AUTH0_MOTHERSHIP_AUDIENCE,
    cacheLocation: 'localstorage',
  })

  if (!LD_KEY) {
    throw new Error('LD_KEY not found')
  }

  if (isLocalEnv()) {
    envOverrides.load()
  }

  registerModalManager()

  await Promise.all([
    initLaunchDarkly(LD_KEY, await getLaunchDarklyAnonymousContext()),
    translationProvider.initialize(),
    loadUserData(),
  ])
}

async function checkCookieConsent() {
  try {
    const consent = await getCookieConsent()
    if (consent.ANALYTICS) {
      startTracking()
    }
    return consent
  } catch (error) {
    logger.error(
      `[orcas-shell - checkCookieConsent] Error checking cookie consent`,
      { error }
    )
  }
}

async function start() {
  await loadStyles('body')
  themeProvider('html', Themes.Light)
  // don't load data and mount the app until user gives cookie consent
  await checkCookieConsent()

  try {
    await initialize()
  } catch (error) {
    logger.error(`Orcas shell init`, { error })
  } finally {
    const app = createApp(App)

    registerRoutesAndMiddlewares()
    app.use(router)

    registerPlugins(app)

    app.mount('#app')
  }
}

start()
