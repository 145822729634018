import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js'

import { Tenant } from '@sennder/senn-node-microfrontend-interfaces'
import { getAuth0Instance } from '@sennder/senn-node-auth0-frontend'

import { AUTH0_USE_TOKEN_POPUP } from '@/common/config'
import { cusAuthInstance } from '@/services/cusAuth'

export async function getAuth0Token(
  options?: GetTokenSilentlyOptions,
  usePopup = false,
  throwException = false
): Promise<string | null> {
  let haveToUsePopup = usePopup

  if (options?.audience) {
    haveToUsePopup = AUTH0_USE_TOKEN_POPUP
  }

  return getAuth0TokenInternal(options, haveToUsePopup, throwException)
}

export async function getAuth0Header(
  options?: GetTokenSilentlyOptions,
  usePopup = false,
  throwException = false
): Promise<string | null> {
  const token = await getAuth0Token(options, usePopup, throwException)

  return token ? `Bearer ${token}` : null
}

/**
 * Parses tenant from the auth token. Defaults to SENNDER if no tenant is found.
 */
export const parseTenantFromToken = async (
  options?: GetTokenSilentlyOptions
): Promise<Tenant> => {
  const auth0Token = await getAuth0Token(options)
  if (auth0Token) {
    const tenant = extractTenantFromToken(auth0Token)
    if (tenant) {
      return tenant
    }
  }
  return Tenant.SENNDER
}

async function getAuth0TokenInternal(
  options?: GetTokenSilentlyOptions,
  usePopup = false,
  throwException = false
): Promise<string | null> {
  const auth0Instance = getAuth0Instance()

  const isAuthenticatedSPA = await auth0Instance?.isAuthenticated()

  if (isAuthenticatedSPA) {
    return await auth0Instance.getToken(options, usePopup, throwException)
  }

  const isAuthenticatedByCUS = await cusAuthInstance.isAuthenticated()

  if (isAuthenticatedByCUS) {
    return await cusAuthInstance.getToken(options)
  }

  return null
}

const parseJwtSafe = (token: string): Record<string, any> | null => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch {
    return null
  }
}

export const extractTenantFromToken = (token: string): Tenant | null => {
  const parsedToken = parseJwtSafe(token)
  if (parsedToken) {
    const tenantKey = Object.keys(parsedToken).find((key) =>
      key.includes('tenant')
    )
    if (tenantKey) {
      return (
        (Object.values(Tenant).find(
          (key) => key === parsedToken[tenantKey]
        ) as Tenant) ?? null
      )
    }
  }
  return null
}
