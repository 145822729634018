import { ICUSUser } from '@/types/cus'
import { IUser } from '@sennder/senn-node-microfrontend-interfaces'

/**
 * This function maps the user data returned from the CUS to the
 * user interface normally returned by Mothership.
 * @param cusUserData // CUS user data
 * @returns // CUS data following the Mothership IUser interface
 */
const mapCUSToMothershipUserData = (cusUserData: ICUSUser): IUser => {
  const language = cusUserData.info?.language || 'en'

  // Fields labeled with (M) are not present in the CUS and will be deprecated
  return {
    cookies: cusUserData.info?.cookies || [],
    firstLogin: !cusUserData.info?.first_login,
    firstName: cusUserData.first_name,
    fullNameWithoutTitle: `${cusUserData.first_name} ${cusUserData.last_name}`,
    id: cusUserData.mothership_id,
    uuid: cusUserData.id,
    language: language,
    lastName: cusUserData.last_name,
    timezone: 'Europe/Berlin',
    uiLanguage: language,
    username: cusUserData.email,
    email: cusUserData.email,
    hasAcceptedLatestTerms: cusUserData.has_accepted_latest_tnc,
    featureFlags: [], // (M)
    groupNames: [], // (M)
    inCustomerGroup: false, // (M)
    inFinanceGroup: false, // (M)
    inStaffGroup: false, // (M)
    paymentTermPopUpDaysPast: 0, // (M)
    permissions: [], // (M)
    positionTitle: '', // (M)
    consent: cusUserData.consent,
  }
}

export { mapCUSToMothershipUserData }
