import { getStateFeatureFlags, getStateUser } from '@/store/getters'
import {
  MicrofrontendEvents,
  onAcceptTermsAndConditions,
  subscribe,
} from '@sennder/senn-node-microfrontend-event-bus'
import router from '@/router'
import { logout } from '@/store/logoutActions'

const termsRouterName = 'TermsAndConditions'

const handleTermsResponse = async (payload: onAcceptTermsAndConditions) => {
  if (!payload.accepted) {
    await logout()
    return
  }

  let routePath = '/'
  if (router.currentRoute.value.query.redirectTo) {
    routePath = router.currentRoute.value.query.redirectTo.toString()
  }
  router.push(routePath)
}

export const redirectToTerms = async (): Promise<void> => {
  await router.isReady()

  const currentRoute = router.currentRoute.value
  const routeName = currentRoute.name?.toString() || ''

  if (routeName !== termsRouterName) {
    await router.push({
      name: termsRouterName,
      query: {
        redirectTo: currentRoute.path.toString(),
      },
    })
  }
}

/**
 * Checks if the user has accepted the latest terms or needs to provide marketing consent,
 * and redirects to the terms page if necessary.
 *
 * This function performs the following checks:
 * 1. Retrieves the current user state.
 * 2. Checks if the marketing consent feature is enabled.
 * 3. Determines if the user needs to accept marketing opt-in.
 * 4. Determines if the marketing opt-in modal should be shown.
 * 5. Redirects to the terms page if the user has not accepted the latest terms or if the marketing opt-in modal should be shown.
 */
export const checkUserTermsOrConsentAndRedirect = () => {
  const user = getStateUser()
  const consent = user.consent

  const isUserMarketingConsentFeatureEnabled = !!getStateFeatureFlags()['ENABLE_CUS-MARKETING-OPT-IN']
  const shouldUserAcceptOptIn = consent && (consent.marketingOptInAt === null)
  const shouldShowMarketingOptInModal = isUserMarketingConsentFeatureEnabled && shouldUserAcceptOptIn

  if (!user.hasAcceptedLatestTerms || shouldShowMarketingOptInModal) {
    redirectToTerms()
  }
}

export const registerModalManager = () => {
  subscribe<onAcceptTermsAndConditions>(
    MicrofrontendEvents.onAcceptTermsAndConditions,
    (payload) => handleTermsResponse(payload)
  )
}
