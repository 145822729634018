import { ref, watch } from 'vue'
import { Tenant } from '@sennder/senn-node-microfrontend-interfaces'

import { getStateData } from '@/store/getters'
import { parseTenantFromToken } from '@/services/tokenManager'
import { translationProvider } from '@/services/translationProvider'

const subfolderNotSupported = ['en', 'pt']

const SENNDER_URL = 'https://www.sennder.com'

// Currently the carrier-profile-public lib does not export the enum for this field
enum Subsidiary {
  GERMANY = 1,
  POLAND = 2,
  ITALY = 3,
  SPAIN = 4,
  ROMANIA = 5,
}

async function getSennderLanguageUrl(relativePath: string) {
  const data = getStateData()
  const subsidiaryId = data.cpsCarrier?.billing.sennder_subsidiary_id

  if (subsidiaryId === Subsidiary.ITALY) {
    return getSennderRelativeUrl(relativePath, 'it')
  }

  const currentLanguage = translationProvider.currentLanguage
  if (!subfolderNotSupported.includes(currentLanguage)) {
    return getSennderRelativeUrl(relativePath, currentLanguage)
  }

  return getSennderRelativeUrl(relativePath)
}

const getSennderRelativeUrl = (relativePath: string, language?: string) =>
  `${SENNDER_URL}/${language ? `${language}/` : ''}${relativePath}`

/**
 * Returns a reactive language-specific full URL to a sennder.com page
 */
export const useSennderLanguageUrl = (relativePath: string) => {
  const url = ref('')
  watch(
    () => translationProvider.currentLanguage,
    async () => {
      url.value = await getSennderLanguageUrl(relativePath)
    },
    { immediate: true }
  )
  return url
}

/**
 * Returns a reactive language-specific full URL to a sennder.com FAQ page
 */
export const useFaqUrl = () => {
  const url = ref('')
  watch(
    () => getStateData().language,
    async () => {
      const tenant = await parseTenantFromToken()
      if (tenant === Tenant.POSTE) {
        url.value = getSennderRelativeUrl('pages/orcas-italia-faq', 'en')
      } else {
        url.value = getSennderRelativeUrl(
          `pages/carriers-faq`,
          getStateData().language
        )
      }
    },
    { immediate: true }
  )
  return url
}
