import { loadModule } from '../loader'
import { ENV } from '@/common/config'

const NAMESPACE = 'carriersignupmfcomponent'
const REMOTE = './carriersignupmfcomponentApp'

// For local development set dev to 'http://localhost:9107/'
const urls = {
  dev: 'https://cdn.dev.cloud.sennder.com/npm/carrier-signup-mf-component/',
  uat: 'https://cdn.uat.cloud.sennder.com/npm/carrier-signup-mf-component/',
  prod: 'https://cdn.cloud.sennder.com/npm/carrier-signup-mf-component/',
}

const environment = ENV || 'dev'

export const carrierSignup = {
  bootstrap: async () => {
    if (carrierSignup.mount) return carrierSignup.mount
    const { mount } = await loadModule(
      `${urls[environment as keyof typeof urls]}remoteEntry.js`,
      NAMESPACE,
      REMOTE
    )
    carrierSignup.mount = mount
    return mount
  },
  mount: null,
}
