export enum ANALYTICS_EVENTS {
  SUCCESSFUL_LOGIN = 'orcas/system/login-success',
  MENU_ONBOARDING_CLICK = 'orcas/click/onboarding/open',
  MENU_MARKETPLACE_CLICK = 'orcas/click/marketplace/open',
  MENU_ASSIGNMENTS_CLICK = 'orcas/click/assignments/open',
  MENU_PLANNER_CLICK = 'orcas/click/planner/open',
  MENU_FLEET_CLICK = 'orcas/click/fleet/open',
  MENU_ORDERS_CLICK = 'orcas/click/orders/open',
  MENU_CONTRACTS_CLICK = 'orcas/click/contracts/open',
  MENU_PAYMENTS_CLICK = 'orcas/click/payments/open',
  MENU_NOTIFICATIONS_CLICK = 'orcas/click/notifications/open',
  MENU_FAQ_CLICK = 'orcas/click/icon-faq',
  MENU_PROFILE_CLICK = 'orcas/click/icon-account',
  MENU_COMPANY_PROFILE_CLICK = 'orcas/click/menu-account-company',
  MENU_LANGUAGE_CLICK = 'orcas/click/menu-account-language',
  LANGUAGE_CHANGE = 'orcas/click/select-language',
  INVITE_TEAM_MEMBER = 'orcas/click/menu-account-invite-team',
  MENU_LOGOUT_CLICK = 'orcas/click/logout',
  MENU_ICON_CLICK = 'orcas/click/icon-orcas',
  APP_BANNER_VIEW = 'orcas/view/app-banner',
  APP_BANNER_OPEN = 'orcas/click/app-banner/open',
  APP_BANNER_CLOSE = 'orcas/click/app-banner/close',
  POSTE_BANNER_CLICK = 'orcas/click/poste-banner',
}
