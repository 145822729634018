import {
  IShellLogProvider,
  ILogContext,
} from '@sennder/senn-node-microfrontend-interfaces'
import { ShellLogger } from '@sennder/shell-utilities'

import { loggerInstance } from '.'

type OrcasLogContext = ILogContext & { carrierId?: number | string }

class OrcasShellLogger extends ShellLogger<OrcasLogContext> {
  constructor(context: OrcasLogContext, getLogger: () => IShellLogProvider) {
    super(context, getLogger)
    this.setCarrierId = this.setCarrierId.bind(this)
  }

  public setCarrierId(carrierId: string | number) {
    this.loggerContext.carrierId = carrierId
  }
}

export const logger = new OrcasShellLogger(
  {
    module: 'orcas-shell',
    codeOwners: 'frontend-systems',
  },
  () => loggerInstance
)
