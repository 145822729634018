import { getAuth0Instance } from '@sennder/senn-node-auth0-frontend'
import { logger } from '@/services/logger/loggers'
import { clearState } from '@/store/setters'
import { cusAuthInstance } from '@/services/cusAuth'
import { anonymizeTracking } from '@/services/tracking'
import { anonymizeUserInLaunchDarkly } from '@/services/launchDarkly'

export type BaseLogoutOptions = {
  callBackend?: boolean
  calledByUser?: boolean
  redirectTo?: string
  replaceUrlTo?: string
}

const logoutWithAuth0 = async () => {
  const auth0Instance = getAuth0Instance()
  try {
    await auth0Instance.logout({ returnTo: window.location.origin })
  } catch (error) {
    logger.error('[Orcas shell] Cant logout with Auth0 token: ', { error })
  }
}

export const logout = async (
  options: BaseLogoutOptions = { callBackend: false, calledByUser: false }
) => {
  const auth0Instance = getAuth0Instance()
  if (!auth0Instance) {
    throw Error('[Orcas shell] Auth0 was not initialized')
  }
  const isAuthenticatedSPA = await auth0Instance.isAuthenticated()
  const isAuthenticatedRO = await cusAuthInstance.isAuthenticated()

  if (isAuthenticatedSPA) {
    await logoutWithAuth0()
  } else if (isAuthenticatedRO) {
    await cusAuthInstance.logout()
  }
  clearState()
  localStorage.removeItem('authenticationStatus')
  logger.info('[Orcas shell] Logout successful', options)
  logger.unsetUserData()
  let nextUrl = '/login'
  if (options.redirectTo) {
    nextUrl += `?redirectTo=${encodeURIComponent(options.redirectTo)}`
  } else if (options.replaceUrlTo) {
    // TODO: this is a temporary solution, should be removed after perform full redirect to the new URL
    nextUrl = options.replaceUrlTo
  }
  // TODO: this is a temporary solution before mFs refactor tenants-related state management.
  window.location.replace(nextUrl)
  // router.push(nextUrl)

  anonymizeTracking()
  await anonymizeUserInLaunchDarkly()
}
