import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'

import { getAuth0Token } from '@/services/tokenManager'
import { DEFAULT_ROUTE } from '@/common/constants'
import { i18n } from '@/services/i18n'
import { getStateData } from '@/store/getters'
import { notify } from '@/store/notify'
import { getFirstVisibleModulePath } from '../routes'

export type MiddlewareOptions = {
  to: RouteLocationNormalized
  from: RouteLocationNormalized
}

export type Middleware = (
  options: MiddlewareOptions
) =>
  | RouteLocationRaw
  | boolean
  | string
  | Promise<RouteLocationRaw | boolean | string>

export const isCompanyCarrier: Middleware = async () => {
  if (getStateData().company?.isCarrier) {
    return true
  } else {
    notify(
      i18n.global.t('auth.unauthorized.is_carrier', {
        support: 'support@sennder.com',
      }),
      'error'
    )
    const { logout } = await import('@/store/logoutActions')
    await logout({ callBackend: true })
    return false
  }
}

export const isAuthenticated: Middleware = async ({ to }) => {
  const isAuthenticated = !!(await getAuth0Token())
  if (isAuthenticated) {
    return true
  } else {
    return {
      path: '/login',
      query: { redirectTo: encodeURIComponent(to.fullPath) },
    }
  }
}

export const isRootAuthenticated: Middleware = async () => {
  const isAuthenticated = !!(await getAuth0Token())
  if (isAuthenticated) {
    return { path: getFirstVisibleModulePath() }
  } else {
    return { name: 'Login' }
  }
}

export const isNotAuthenticated: Middleware = async () => {
  const isAuthenticated = !!(await getAuth0Token())
  if (isAuthenticated) {
    return { path: DEFAULT_ROUTE }
  } else {
    return true
  }
}

export const projectMiddlewares = {
  isCompanyCarrier,
  isAuthenticated,
  isRootAuthenticated,
  isNotAuthenticated,
}
