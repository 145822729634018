import { TRANSLATIONS_URL } from '@/common/config'
import { i18n } from '@/services/i18n'
import { logger } from '@/services/logger/loggers'
import { setStoreLanguage } from '@/store/setters'
import { TranslationProvider } from '@sennder/shell-utilities'
import { defaultLanguage } from '@/services/i18n'

if (!TRANSLATIONS_URL) {
  throw new Error('TRANSLATIONS_URL is not defined')
}

export const translationProvider = new TranslationProvider({
  cloudServiceUrl: TRANSLATIONS_URL,
  supported: ['en', 'de', 'es', 'fr', 'it', 'pl', 'ru', 'pt'],
  defaultLanguage,
  logger,
  afterTranslationChanged: (language, translations) => {
    i18n.global.setLocaleMessage(language, translations)
    i18n.global.locale = language
    setStoreLanguage(language)
  },
  loadLocalTranslation: async (language) =>
    import(
      /* webpackChunkName: "chunk.i18n-[request]" */ `@/languages/${language}.json`
    ),
})
