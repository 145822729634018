import { analytics } from '@/services/analyticsProvider'
import {
  AppBannerOptions,
  onShowAppBanner,
  MicrofrontendEvents,
  subscribe,
  onCloseAppBanner,
} from '@sennder/senn-node-microfrontend-event-bus'
import Cookie from 'js-cookie'
import { getAppBannerOptions } from './config'
import { ANALYTICS_EVENTS } from '@/services/analyticsEvents'

const defaultOptions = {
  api: 'true',
  enabledPlatforms: 'android,ios',
}

let bannerInitialized = false
let bannerInstance: any = null
let alwaysShowBanner = false

const restoreDefaultSettings = () => {
  Cookie.remove('smartbanner_exited')
  const html = document.querySelector('html')
  if (html) {
    html.removeAttribute('data-smartbanner-original-margin-top')
    html.style.marginTop = ''
  }
}

const addEventListeners = () => {
  document.addEventListener('smartbanner.view', () => {
    analytics.trackEvent(ANALYTICS_EVENTS.APP_BANNER_VIEW)
  })

  document.addEventListener('smartbanner.clickout', () => {
    analytics.trackEvent(ANALYTICS_EVENTS.APP_BANNER_OPEN)
  })

  document.addEventListener('smartbanner.exit', () => {
    analytics.trackEvent(ANALYTICS_EVENTS.APP_BANNER_CLOSE)
  })
}

const initializeSmartBanner = async (): Promise<boolean> => {
  for (const [key, value] of Object.entries(defaultOptions)) {
    const element = document.createElement('meta')
    element.setAttribute('name', `smartbanner:${key}`)
    element.content = value
    document.getElementsByTagName('head')[0].appendChild(element)
  }

  await require('smartbanner.js/dist/smartbanner.css')
  const { default: SmartBanner } = await import(
    'smartbanner.js/src/smartbanner.js' as any
  )

  addEventListeners()

  bannerInstance = new SmartBanner(defaultOptions)
  bannerInitialized = true
  return bannerInitialized
}

export const registerSmartBanner = () => {
  subscribe<onShowAppBanner>(
    MicrofrontendEvents.onShowAppBanner,
    async (payload) => {
      alwaysShowBanner = !!payload?.alwaysShow
      if (!bannerInitialized) {
        await initializeSmartBanner()
      }
      publishBanner(payload?.bannerOptions)
    }
  )
  subscribe<onCloseAppBanner>(MicrofrontendEvents.onCloseAppBanner, closeBanner)
}

const isBannerDisplayed = () => !!document.querySelector('body > .smartbanner')

const publishBanner = (options: AppBannerOptions = getAppBannerOptions()) => {
  if (!bannerInitialized) {
    return
  }
  if (alwaysShowBanner) {
    restoreDefaultSettings()
  }
  bannerInstance.options = { ...options, ...defaultOptions }
  bannerInstance.publish()
}

const closeBanner = () => {
  if (!bannerInitialized) {
    return
  }
  if (bannerInstance && isBannerDisplayed()) {
    bannerInstance.exit()
  }
}
