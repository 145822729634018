import { AnalyticsProvider } from '@sennder/shell-utilities'
import { IAnalyticsContext } from '@sennder/senn-node-microfrontend-interfaces'

import { logger } from '@/services/logger/loggers'

const getTrackEventName = (name: string): string => {
  // TODO: We need to check with all teams to use the latest pattern naming
  const isOrcasEvent = name.startsWith('orcas-')
  const isNewTMSEvent = name.startsWith('orcas/')
  return isOrcasEvent || isNewTMSEvent ? name : `tms-${name}`
}

export class AppAnalyticsProvider extends AnalyticsProvider {
  // enforce app specific analytics configuration
  constructor(context: IAnalyticsContext) {
    super({
      context,
      logger,
      messageSource: 'orcas',
      dedupePageEvents: true,
      formatEventName: getTrackEventName,
    })
  }
}

export const analytics = new AppAnalyticsProvider({
  module: 'orcas',
  submodule: '',
})
