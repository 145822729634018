import {
  BaseRequest,
  HttpInterceptor,
  HTTPResponseTransformer,
  IHTTPProvider,
  RequestOptions,
} from '../types'

export default class FetchProvider implements IHTTPProvider {
  private interceptors: HttpInterceptor[] = []
  private responseTransformers: HTTPResponseTransformer[] = []

  private computeQueryParams(query: Record<string, any>) {
    if (!query) return ''
    const queryParams = new URLSearchParams(query)
    return '?' + queryParams.toString()
  }

  addResponseTransformer(transformer: HTTPResponseTransformer) {
    if (!transformer || typeof transformer !== 'function') {
      throw Error(
        '[orcas-shell - FetchProvider]: HTTPResponseTransformer is required and should be a function'
      )
    }

    this.responseTransformers.push(transformer)

    return this
  }

  private transformResponse(response: any) {
    if (!response) {
      return null
    }

    let data = response

    this.responseTransformers.forEach((transformer) => {
      data = transformer(response)
    })

    return data
  }

  private request<Res>(options: BaseRequest) {
    this.onRequest(options)
    const body = options.data ? JSON.stringify(options.data) : null
    return fetch(
      options.baseUrl +
        options.path +
        (options.query ? this.computeQueryParams(options.query) : ''),
      { headers: options.headers, body, method: options.method }
    )
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response)
        }
        return response
      })
      .then(this.getJsonFromResponse)
      .then((response) => {
        const data = this.transformResponse(response)
        this.onResponse<Res>(data)
        return data
      })
      .catch((error) => {
        this.onError(error)
        throw error
      })
  }

  private async getJsonFromResponse(response: Response) {
    try {
      //try..catch to handle wrong JSON issue as well as no-json issue
      return await response.json()
    } catch (e) {
      return null
    }
  }

  private onError(response: Response) {
    this.interceptors.forEach((interceptor) => {
      if (interceptor.onError) {
        interceptor.onError(response.status)
      }
    })
  }

  private onRequest(request: BaseRequest) {
    this.interceptors.forEach((interceptor) => {
      if (interceptor.onRequest) {
        interceptor.onRequest(request)
      }
    })
  }

  private onResponse<Res>(response: Res) {
    this.interceptors.forEach((interceptor) => {
      if (interceptor.onResponse) {
        interceptor.onResponse(response as object)
      }
    })
  }

  public addInterceptor(interceptor: HttpInterceptor) {
    if (
      interceptor &&
      (interceptor.onError || interceptor.onRequest || interceptor.onResponse)
    ) {
      this.interceptors.push(interceptor)
    } else {
      throw Error('[orcas-shell - FetchProvider]: Empty interceptor')
    }

    return this
  }

  delete<Res>(path: string, requestOptions: RequestOptions): Promise<Res> {
    return this.request<Res>({ path, method: 'DELETE', ...requestOptions })
  }

  get<Res>(path: string, requestOptions: RequestOptions): Promise<Res> {
    return this.request<Res>({ path, method: 'GET', ...requestOptions })
  }

  post<Res>(path: string, requestOptions: RequestOptions): Promise<Res> {
    return this.request<Res>({ path, method: 'POST', ...requestOptions })
  }

  put<Res>(path: string, requestOptions: RequestOptions): Promise<Res> {
    return this.request<Res>({ path, method: 'PUT', ...requestOptions })
  }
}
