import { RouteLocationNormalized } from 'vue-router'
import { IModulesConfig } from '@sennder/senn-node-microfrontend-interfaces'
import { OrcasStoreData } from '@/store'
import { moduleConfiguration } from '@/modules-configuration'

type VisibilityHandler = {
  handler: (data: OrcasStoreData) => boolean
  fallbackRoute: Record<string, any>
}

const handlers: {
  [componentName: string]: {
    handler: (data: OrcasStoreData) => boolean
    fallbackRoute?: Record<string, any>
  }
} = {
  marketplace: {
    handler: (data: OrcasStoreData) => {
      return !data.hasOnboardingFormAccess
    },
    fallbackRoute: { path: '/onboarding' },
  },
  'onboarding-component': {
    handler: (data: OrcasStoreData) => {
      return data.hasOnboardingFormAccess
    },
    fallbackRoute: { path: '/marketplace' },
  },
  'sennfuel-mf-component': {
    handler: () => {
      // const { company } = data
      // const validVatCountries = ['DE', 'AT', 'IT', 'PL', 'EE', 'LV', 'LT']

      // return validVatCountries.some((vat: string) =>
      //  company?.vatId?.toString()?.toUpperCase()?.startsWith(vat)
      // )
      return true
    },
  },
  'planner-mf-component': {
    handler: () => {
      // handler: (state: IMicrofrontendData<IOrcasSharedData>) => {
      // const hideForCountries = ['DE', 'PL', 'FR']
      // const companyVat = state.data?.company?.vatId?.toString()
      // const isValidCarrier = !hideForCountries.some((countryCode) =>
      //   companyVat?.startsWith(countryCode)
      // )
      // return isValidCarrier
      return false
    },
  },
  chartering: {
    handler: () => {
      return false
    },
  },
}

export const getVisibilityHandler = (
  componentName: string
): VisibilityHandler | null => {
  if (!handlers[componentName]) {
    return null
  }

  const { handler, fallbackRoute } = handlers[componentName]

  return {
    handler,
    fallbackRoute: fallbackRoute || { path: '/' },
  }
}

export const isModuleVisible = (
  module: IModulesConfig,
  data: OrcasStoreData
) => {
  const isEnabledByFeatureFlag =
    !module.featureFlag || data.featureFlags[module.featureFlag]

  const visibilityHandler = getVisibilityHandler(module.component)

  const isEnabledByVisibilityHandler =
    !visibilityHandler || visibilityHandler.handler(data)

  return isEnabledByFeatureFlag && isEnabledByVisibilityHandler
}

export const getFeatureFlagForRoute = (route: RouteLocationNormalized) => {
  const module = moduleConfiguration.find(
    (module: IModulesConfig) => module.name === route.name
  )
  return module?.featureFlag
}
