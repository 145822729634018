import { Carrier } from '@sennder/carrier-profile-public-axios-client'
import { ICarrier } from './carrier'

/**
 * Convert Carrier object to ICarrier
 * @deprecated do not use, instead use `IOrcasSharedData.cpsCarrier`
 * @param carrierResponse
 */
export const deserialize = (carrierResponse: Carrier): ICarrier => {
  return {
    id: carrierResponse.id,
    mothershipId: carrierResponse.mothership_id,
    isDenylisted: carrierResponse.is_denylisted,
    reasonForDenylistingMasked: carrierResponse.reason_for_denylisting_masked,
    billing: {
      // @ts-ignore - as sennderSubsidiaryId is not defined in Carrier interface
      sennderSubsidiaryId: carrierResponse.billing.sennder_subsidiary_id,
    },
    gpsIntegration: {
      co3IntegrationStatus:
        carrierResponse.gps_integration?.co3_integration_status,
      sennderAppIntegrationCompletedAt:
        carrierResponse.gps_integration?.sennder_app_integration_completed_at,
      project44IntegrationCompletedAt:
        carrierResponse.gps_integration?.project_44_integration_completed_at,
    },
    vat: carrierResponse.vat,
  }
}
